import React, {useContext, useEffect} from 'react';
import Layout from '../ui/layout/Layout';
import {Container, Section} from '../ui/layout/Grid';

import lang from '../lang/pages/nutzungsbedingungen.lang';
import Headline from '../ui/components/Headline';
import LocationContext from '../ui/utils/LocationContext';
import {scrollToAnchorStart} from '../utils/scroll-utils';

const TermsOfUse = () => {
    const location = useContext(LocationContext);
    useEffect(() => {
        if (location && location.hash === '#teilnahmebedingungen') {
            scrollToAnchorStart('conditionOfParticipation');
        }
    }, [location]);
    return (
        <Layout>
            <Section>
                <Container width="narrow">
                    <Headline type="h1">Nutzungs&shy;bedingungen</Headline>
                    {lang.termsOfUse}
                    <Section anchor="conditionOfParticipation">
                        <Headline type="h2">Teilnahmebedingungen</Headline>
                        {lang.conditionOfParticipation}
                    </Section>
                </Container>
            </Section>
        </Layout>
    );
};

export default TermsOfUse;
