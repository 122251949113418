import styled from 'styled-components';

import {
    globalListStyle,
    globalUnOrderedListStyle,
    globalOrderedListStyle,
} from '../../../styles/global';
import {font} from '../../../styles/variables';

export const UnorderedList = styled.ul`
    ${globalListStyle}
    ${globalUnOrderedListStyle}
`;

export const ListItem = styled.li`
    display: inline;
    li::before {
        content: '' !important;
    }
`;

export const OrderedList = styled.ol`
    ${globalListStyle}
    ${globalOrderedListStyle}

    counter-reset: no-reset ${(props) => props.start} reset 0;

    li {
        counter-increment: ${(props) => (props.start ? 'no-reset' : 'reset')};

        &::before {
            content: ${(props) =>
                props.start ? "counter(no-reset) '. '" : "counter(reset) '. '"};
            font-weight: ${(props) => font.weight[props.counterFontWeight]};
        }
        ul {
            counter-reset: reset;
            li::before {
                content: '' !important;
            }
        }
    }
`;
