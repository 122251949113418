import React from 'react';
import Paragraph from '../../ui/components/Paragraph';
import List from '../../ui/components/List';
import Link from '../../ui/components/Link';

export default {
    termsOfUse: (
        <>
            <Paragraph>
                <b>
                    Nutzungsbedingungen für Apotique
                    <br />
                    Eine Initiative von Pohl-Boskamp
                </b>
            </Paragraph>
            <Paragraph>
                Aus Gründen der besseren Lesbarkeit wurde im Text die männliche
                Form gewählt. Die personenbezogenen Angaben sind nicht
                geschlechterspezifisch gemeint und beziehen sich auf Angehörige
                aller Geschlechter.
            </Paragraph>
            <Paragraph>
                <b>1. Anbieter </b>
                <br />
                Anbieter ist die G. Pohl-Boskamp GmbH & Co. KG, Kieler Straße
                11, 25551 Hohenlockstedt, nachfolgend „Pohl-Boskamp“, „Anbieter“
                oder „wir“ genannt.
            </Paragraph>
            <Paragraph>
                <b>2. Nutzer</b>
                <br />
                (1) Du bist nutzungsberechtigt, wenn Du volljährig bist, deinen
                Wohnsitz in Deutschland hast und Dich in der praktischen
                Ausbildung zum Apotheker, pharmazeutisch technischen Assistenten
                (PTA) oder pharmazeutisch-kaufmännischen Assistenten (PKA)
                befindest, oder dem Beruf des Apothekers, des
                Pharmazie-Ingenieurs, des Pharmazeutisch-technischen Assistenten
                (PTA) oder des Pharmazeutisch-kaufmännischen Assistenten (PKA)
                in einer Apotheke nachgehst.
                <br />
                <br />
                (2) Nicht nutzungsberechtigt sind alle an der Konzeption und
                Umsetzung der Initiative beteiligten Personen und Mitarbeiter
                von Pohl-Boskamp sowie deren Familienmitglieder.
                <br />
                <br />
                (3) Um das Angebot nutzen zu können, musst Du Dich unter
                www.apotique.de registrieren und ein Profil anlegen („Nutzer“).
                Mit der Registrierung bestätigst du, dass Du die Einwilligung
                des Apothekeninhabers für die Nutzung des Angebots eingeholt
                hast.
                <br />
            </Paragraph>
            <Paragraph>
                <b>3. Funktionsweise des Angebots</b>
                <br />
                (1) Durch das erfolgreiche Abschließen von Schulungsmodulen,
                Aktionen oder Präsenzschulungen in der Offizin erhältst Du
                Punkte, die Du ab einem bestimmten Punktewert anschließend gegen
                Prämien eintauschen kannst. Ein Anspruch auf Eintausch der
                Punkte in Geld besteht nicht.
                <br />
                <br />
                (2) Die Nutzung des Angebots ist unentgeltlich. Die Punkte- und
                Prämienvergabe ist nicht vom Erwerb einer Ware oder
                Dienstleistung abhängig.
                <br />
                <br />
                (3) Dir stehen wechselnde Schulungsmodule zur Verfügung. In
                unserem Ermessen können außerdem Präsenzschulungen in der
                Offizin und weitere Aktionen angeboten werden, mit denen du
                Punkte erhalten kannst. Diese Schulungsmodule und Aktionen
                schlagen wir Dir auf Grundlage Deiner im Profil hinterlegten
                Interessen vor.
                <br />
                <br />
                (4) Pohl-Boskamp weist den Schulungsmodulen und den weiteren
                Aktionen Punktwerte zu.
                <br />
                <br />
                (5) Für jedes Schulungsmodul und jede Aktion kannst Du nur
                einmalig Punkte erhalten.
                <br />
                <br />
                (6) Am Ende jedes erfolgreich abgeschlossenen Schulungsmoduls
                und jeder erfolgreich abgeschlossenen Aktion werden Dir die
                erreichten Punkte auf Dein Punktekonto und auf das Punktekonto
                deiner Apotheke gutgeschrieben.
                <br />
                <br />
                (7) Dein Punktekonto umfasst maximal 1.620 Punkte. Nach
                Erreichen dieses Wertes können deinem Punktekonto keine weiteren
                Punkte mehr gutgeschrieben werden, bis die Punkte durch
                Eintauschen abgebaut oder verfallen sind.
                <br />
                <br />
                (8) Dir wird der Stand des Punktekontos deiner Apotheke, der
                Stand der Punktekonten deiner Kollegen in der gleichen Apotheke
                und die Platzierung deiner Apotheke im Vergleich zu den anderen
                Apotheken angezeigt.
                <br />
                <br />
                (9) Pohl-Boskamp behält sich vor, die Funktionsweise des
                Angebots jederzeit zu ändern.
            </Paragraph>
            <Paragraph>
                <b>4. Prämien</b>
                <br />
                (1) Du kannst deine Punkte gegen Prämien eintauschen. Die Punkte
                auf dem Punktekonto der Apotheke können nicht eingetauscht
                werden.
                <br />
                <br />
                (2) Pohl-Boskamp bestimmt die Art und den Wert der Prämien und
                legt fest, wie viele Punkte erforderlich sind, um eine Prämie zu
                erhalten.
                <br />
                <br />
                (3) Sachprämien werden per Post an die vom Nutzer angegebene
                Apotheken-Adresse versandt. Gutscheine werden in der Regel per
                E-Mail versandt.
                <br />
            </Paragraph>
            <Paragraph>
                <b>5. Verfügbarkeit des Angebots</b>
                <br />
                (1) Pohl-Boskamp behält sich vor, das Angebot und einzelne
                Funktionen, einschließlich der Schulungsmodule und Aktionen,
                ohne Vorankündigung jederzeit zu ändern oder einzustellen. Dies
                gilt insbesondere bei Manipulationsversuchen, technischen
                Fehlern oder Störungen.
                <br />
                <br />
                (2) Bei Einstellung des Angebots verfallen die angesammelten
                Punkte. Ein Anspruch auf Eintausch der Punkte gegen Prämien oder
                auf Auszahlung in Geld entsteht dadurch nicht.
            </Paragraph>
            <Paragraph>
                <b>6. Ausschluss von Nutzern</b>
                <br />
                (1) Pohl-Boskamp ist berechtigt, Nutzer von der Nutzung des
                Angebots zeitweise oder vollständig auszuschließen. Ein Nutzer
                kann insbesondere ausgeschlossen werden,
                <br />
                <List
                    type="ul"
                    items={[
                        'bei rechtswidrigen Äußerungen, insbesondere bei Beleidigungen, Verleumdungen, Wettbewerbs-, Marken- oder Urheberrechtsverstößen und Propagandadelikten, oder bei einem sonstigen Verstoß gegen gesetzliche Vorschriften, ',
                        'bei Angabe unzutreffender oder unvollständiger Daten,',
                        'bei Mehrfachteilnahme,',
                        'bei Einsatz automatisierter Systeme und sonstiger technischer Verfahren zur Manipulation des Angebots,',
                        'bei Offenbarung der Zugangsdaten gegenüber anderen Personen,',
                        'bei sonstigen Versuchen, auf das Angebot oder die Prämienvergabe in unzulässiger Weise Einfluss zu nehmen.',
                    ]}
                />
                <br />
                <br />
                (2) Weiterhin ist Pohl-Boskamp berechtigt, Nutzer
                auszuschließen, wenn der Apothekeninhaber seine Einwilligung zur
                Teilnahme des Nutzers nicht erteilt oder widerruft.
                <br />
                <br />
                (3) Bei Ausschluss eines Nutzers verfallen die erhaltenen
                Punkte. Ein Anspruch auf Eintausch der Punkte gegen Prämien oder
                auf Auszahlung in Geld entsteht dadurch nicht.
            </Paragraph>
            <Paragraph>
                <b>7. Haftung</b>
                <br />
                (1) Bei Vorsatz und grober Fahrlässigkeit haftet Pohl-Boskamp
                unbeschränkt für von ihm beim Nutzer verursachte Schäden. Bei
                leichter Fahrlässigkeit haftet Pohl-Boskamp unbeschränkt für
                Verletzungen des Lebens, des Körpers oder der Gesundheit.
                <br />
                <br />
                (2) In allen anderen Fällen beschränkt sich die Haftung von
                Pohl-Boskamp auf wesentliche Vertragspflichten und auf den
                Ersatz des vorhersehbaren Schadens. Eine wesentliche
                Vertragspflicht ist eine Pflicht, deren Erfüllung die
                ordnungsgemäße Durchführung des Vertrags erst ermöglicht und auf
                deren Einhaltung der Vertragspartner regelmäßig vertrauen darf.
                <br />
                <br />
                (3) Die Haftungsbeschränkung gilt auch für Erfüllungsgehilfen
                von Pohl-Boskamp.
            </Paragraph>
            <Paragraph>
                <b>8. Schlussbestimmungen</b>
                <br />
                (1) Auf alle Streitigkeiten in Verbindung mit dem Angebot oder
                diesen Nutzungsbedingungen findet ausschließlich das Recht der
                Bundesrepublik Deutschland Anwendung.
                <br />
                <br />
                (2) Sollten einzelne Bestimmungen ganz oder teilweise unwirksam
                sein, oder ihre Rechtswirksamkeit später verlieren, wird die
                Gültigkeit der übrigen Nutzungsbedingungen davon nicht berührt.
                Der Anbieter kann die Nutzungsbedingungen jederzeit ohne Angabe
                von Gründen oder Benachrichtigung des Nutzers ändern.
            </Paragraph>
            <Paragraph>Stand 02/2021</Paragraph>
        </>
    ),
    conditionOfParticipation: (
        <>
            <Paragraph>
                <b>
                    Teilnahmebedingungen APOTIQUE Gewinnspiel – Fotoaktion „50
                    Jahre Gelo® Atemwegskompetenz“ im Rahmen der Apothekentour
                    2022
                </b>
            </Paragraph>
            <Paragraph>
                Aus Gründen der besseren Lesbarkeit wurde im Text die männliche
                Form gewählt. Die personenbezogenen Angaben sind nicht
                geschlechterspezifisch gemeint und beziehen sich auf Angehörige
                aller Geschlechter.
            </Paragraph>
            <Paragraph>
                Die nachfolgenden Teilnahmebedingungen gelten für die Fotoaktion
                „50 Jahre Gelo® Atemwegskompetenz“ im Rahmen der Apothekentour
                2022. Die Verlosung findet in dem Zeitraum vom 12.05. 08:00 Uhr
                MET bis zum 31.10.2022 23:59 Uhr MET statt. Mit der Teilnahme an
                der Aktion erkennt der Teilnehmer diese Teilnahmebedingungen
                ausdrücklich an.
            </Paragraph>
            <List
                type="ol"
                items={[
                    <>
                        Die G. Pohl-Boskamp GmbH & Co. KG, Kieler Straße 11,
                        25551 Hohenlockstedt, nachfolgend „Pohl-Boskamp“
                        genannt, ist Veranstalter dieser Aktion. Das Gewinnspiel
                        ist nicht von KitchenAid und nicht von mymuesli
                        veranstaltet oder gesponsert und auch sonst in keinem
                        Zusammenhang mit einer dieser Marken.
                    </>,
                    <>
                        Teilnahmeberechtigt sind alle auf Apotique registrierten
                        Personen, die im Zeitraum vom 07.05.2022 bis 09.10.2022
                        auf der Apothekentour 2022 ein Foto am Pohl-Boskamp
                        Stand gemacht haben und dieses im Anschluss auf der
                        Apotique – bis spätestens Mittwoch nach der jeweiligen
                        Veranstaltung - hochgeladen haben.Von der Teilnahme
                        ausgeschlossen sind Mitarbeiter von Pohl-Boskamp sowie
                        von deren Tochter- und Beteiligungsunternehmen sowie
                        Mitarbeiter von an der Aktion beteiligten Unternehmen
                        und deren Angehörige. Teilnahme und die Gewinnchance
                        sind nicht vom Erwerb einer Ware oder Dienstleistung
                        abhängig. Die Teilnahme ist im Rahmen der Apothekentour
                        2022 nur einmal pro Person möglich.
                    </>,
                    <>
                        Die Teilnahme erfolgt im genannten Zeitraum auf der
                        Apotique:{' '}
                        <Link
                            target="_blank"
                            href="http://www.apotique.de/apothekentour">
                            http://www.apotique.de/apothekentour.
                        </Link>
                        <br />
                        Um teilzunehmen, lädt der Teilnehmer das bei der
                        Fotoaktion angefertigte Foto, welches er per E-Mail
                        erhalten hat, über die Upload-Funktion (Upload-Fenster)
                        nach Registrierung bzw. Anmeldung auf www.apotique.de
                        hoch.
                    </>,
                    <>
                        Unter allen Teilnehmern werden zehn KitchenAids in der
                        Farbe grün plus zehn mymuesli Backsets verlost, sowie
                        100 Trostpreise mymuesli Backmischungen. Die Verlosung
                        findet bis spätestens 4 Wochen nach jedem
                        Veranstaltungswochenende der Apothekentour 2022 statt.
                    </>,
                    <>
                        Das Foto des Teilnehmers kann für kommerzielle und
                        nicht-kommerzielle Zwecke von Pohl-Boskamp verwendet und
                        ausgewertet werden, insbesondere für Zwecke des
                        Marketings und der Print- und Onlinewerbung sowie der
                        Presse- und Öffentlichkeitsarbeit. Dies umfasst die
                        Nutzung des Fotos für in gedruckter Form (in
                        einschlägigen Zeitschriften, in Flyern/Broschüren,
                        Mailings, Printanzeigen, Advertorials), die
                        Veröffentlichung im Internet (insbesondere auf den
                        Internetseiten des Veranstalters, Onlineanzeigen und
                        Advertorials) und in sozialen Netzwerken (gegebenenfalls
                        inklusive Anpassung auf das Format spezifischer Kanäle),
                        die Aufnahme in andere Publikationen (z.B.
                        Veröffentlichungen durch Dritte in
                        Presseberichterstattungen) und die Veröffentlichung auf
                        Bildschirmen (insb. während der Veranstaltung, auf
                        weiteren vergleichbaren Messen und im Apotheken-TV).
                        <br />
                        Der Teilnehmer räumt Pohl-Boskamp mit Abschluss des
                        Hochladevorgangs des Fotos die räumlich, zeitlich und
                        inhaltlich und medial unbeschränkten Nutzungsrechte ein,
                        hier insbesondere das Vervielfältigungsrecht, das
                        Verbreitungsrecht und das Recht der öffentlichen
                        Zugänglichmachung. Pohl-Boskamp darf das Foto selbst
                        oder durch Dritte verändern und bearbeiten (z.B.
                        Formatanpassung, Druckvorbereitung) und mit anderen
                        geschützten Werken verbinden. Pohl-Boskamp darf das Foto
                        an Dritte weiterlizenzieren. Bei Ausschluss des
                        Teilnehmers vom Gewinnspiel und bei Löschung des Posts
                        verzichtet Pohl-Boskamp auf die gewährten Rechte am
                        Foto.
                        <br />
                        Der Teilnehmer bestätigt, dass er über alle Rechte am
                        eingereichten Foto verfügt und alle notwendigen
                        Einwilligungen eingeholt hat, dass das Foto frei von
                        Rechten Dritter ist und keine Persönlichkeitsrechte
                        verletzt und dass er die hiermit eingeräumten Rechte
                        Pohl-Boskamp einräumen darf. Sollten Dritte Ansprüche
                        wegen Verletzung ihrer Rechte geltend machen, stellt der
                        Teilnehmer Pohl-Boskamp von allen Ansprüchen frei. Das
                        Foto darf keine Werke und sonstigen geschützten
                        Leistungen Dritter (z.B. Abbildungen und Logos auf der
                        Kleidung) enthalten. Dies gilt auch im Hinblick auf
                        geschützte Marken. Pohl-Boskamp behält sich das Recht
                        vor, entsprechende Bildausschnitte unkenntlich zu
                        machen.
                    </>,
                    <>
                        Pohl-Boskamp ist berechtigt, Teilnehmer von der
                        Teilnahme an der Aktion auszuschließen. Ein Teilnehmer
                        kann insbesondere ausgeschlossen werden, - bei
                        rechtswidrigen Äußerungen im Rahmen der Aktion,
                        insbesondere bei Beleidigungen, Verleumdungen,
                        Wettbewerbs-, Marken- oder Urheberrechtsverstößen und
                        Propagandadelikten, - bei Angabe unzutreffender oder
                        unvollständiger Daten, - bei Mehrfachteilnahme, - bei
                        Einsatz automatisierter Systeme und sonstiger
                        technischer Verfahren zur Manipulation der Aktion, - bei
                        sonstigen Versuchen, auf das Ergebnis der Aktion in
                        unzulässiger Weise Einfluss zu nehmen.
                    </>,
                    <>
                        Pohl-Boskamp behält sich vor, die Aktion aufgrund
                        unvorhergesehener Umstände ohne Vorankündigung
                        abzubrechen oder zu beenden. Dies gilt insbesondere bei
                        Manipulationsversuchen, technischen Fehlern oder
                        Störungen.
                    </>,
                    <>
                        Eine Barauszahlung des Gewinns ist nicht möglich. Der
                        Rechtsweg ist ausgeschlossen. Auf alle Streitigkeiten in
                        Verbindung mit diesen Teilnahmebedingungen findet
                        ausschließlich das Recht der Bundesrepublik Deutschland
                        Anwendung. Sollten einzelne Bestimmungen ganz oder
                        teilweise unwirksam sein, oder ihre Rechtswirksamkeit
                        später verlieren, wird die Gültigkeit der übrigen
                        Teilnahmebedingungen davon nicht berührt. Pohl-Boskamp
                        kann die Teilnahmebedingungen jederzeit ohne Angabe von
                        Gründen oder Benachrichtigung des Teilnehmers ändern.
                    </>,
                    <>Verarbeitung personenbezogener Daten des Teilnehmers.</>,
                ]}
            />

            <Paragraph>
                Verantwortlicher für die Erhebung, Verarbeitung und Nutzung
                personenbezogener Daten im Rahmen der Aktion:
                <br />
                <br />
                G. Pohl-Boskamp GmbH & Co. KG
                <br />
                Kieler Straße 11
                <br />
                25551 Hohenlockstedt,
                <br />
                Tel.: +49 (0) 4826 59 0<br />
                E-Mail:{' '}
                <Link href="mailto:info@pohl-boskamp.de">
                    info@pohl-boskamp.de
                </Link>
            </Paragraph>
            <br />
            <Paragraph>
                Datenschutzbeauftragter:
                <br />
                G. Pohl-Boskamp GmbH & Co. KG
                <br />
                Datenschutzbeauftragter
                <br />
                Kieler Straße 11
                <br />
                25551 Hohenlockstedt
                <br />
                E-Mail:{' '}
                <Link href="mailto:datenschutz-info@pohl-boskamp.de">
                    datenschutz-info@pohl-boskamp.de
                </Link>
            </Paragraph>
            <Paragraph>
                Gegenstand der Verarbeitung: Verarbeitet werden bei der
                Teilnahme an der Aktion vom Teilnehmer zur Verfügung gestellte
                Daten (Name, Postadresse, E-Mail-Adresse, Arbeitsplatz, Beruf).
            </Paragraph>
            <Paragraph>
                Bei der Betrachtung der Apotique-Webseite und der Nutzung des
                Angebots werden weitere Daten verarbeitet. Die
                Datenschutzerklärung von Apotique findest Du hier:
                <Link
                    target="_blank"
                    href="https://www.apotique.de/datenschutz">
                    https://www.apotique.de/datenschutz
                </Link>
            </Paragraph>
            <Paragraph>
                Zwecke der Verarbeitung: Zwecke der Verarbeitung sind die
                Durchführung der Aktion einschließlich Benachrichtigung des
                Gewinners und Versand/Übergabe des Gewinns, die Information der
                Teilnehmer über den Aktionsverlauf und die Nachberichterstattung
                zur Aktion und zu den Gewinnern in branchenüblicher Weise.
                Weitere Zwecke sind interne Kommunikations- und Dokumentation
                der Aktion und Marketing, Werbung, Public Relation und
                Öffentlichkeitsarbeit in gedruckter Form (in Zeitschriften,
                Flyern/Broschüren, Mailings, Printanzeigen, Advertorials), in
                digitaler Form (insbesondere in den Social-Media-Kanälen des
                Veranstalters, den Internetseiten des Veranstalters,
                Onlineanzeigen und Advertorials sowie in digitalen Besprechungs-
                und Schulungsmaterialien) und auf Bildschirmen (insb. während
                der Veranstaltung, auf weiteren vergleichbaren Messen und im
                Apotheken-TV).
            </Paragraph>
            <Paragraph>
                Rechtsgrundlage für die Verarbeitung Deiner Daten ist die
                Durchführung des mit Dir durch die Teilnahme am Gewinnspiel
                geschlossenen Vertrages. Daneben verarbeiten wir Deine Daten auf
                Grundlage eines Gesetzes, insbesondere aufgrund steuer- und
                handelsrechtlicher Vorgaben. Weiterhin erfolgt die Verarbeitung
                Deiner Daten zur Wahrung unseres berechtigten Interesses; sie
                ist insbesondere erforderlich, um die Aufnahmen für interne
                Zwecke zu dokumentieren und zu archivieren.
            </Paragraph>
            <Paragraph>
                Speicherung: Personenbezogene Daten werden gelöscht, sobald sie
                für die oben genannten Zwecke nicht mehr erforderlich sind. Die
                Kontaktdaten als APOTIQUE-Nutzer bleiben bis zur Sperrung des
                Nutzers oder Löschen des Nutzerkontos oder des Angebots
                gespeichert und werden anschließend gelöscht, es sei denn
                Pohl-Boskamp ist aus gesetzlichen Gründen verpflichtet, die
                Daten über die Dauer des Angebots hinaus zu speichern.
            </Paragraph>
            <Paragraph>
                Übermittlung an Dritte: Wir setzen Marketingagenturen und
                -provider ein, die uns bei der Planung und Durchführung der
                Aktion unterstützen.
            </Paragraph>
            <Paragraph>
                Die Dienstleister verarbeiten die Daten gemäß den Weisungen von
                Pohl-Boskamp und zu den in diesen Datenschutzhinweisen
                beschriebenen Zwecken.
            </Paragraph>
            <Paragraph>DEINE RECHTE:</Paragraph>
            <Paragraph>
                Recht auf Auskunft: Nach Art. 15 DSGVO hast Du das Recht,
                Auskunft über Herkunft, Empfänger und Zweck Deiner von uns
                verarbeiteten personenbezogenen Daten zu verlangen.
            </Paragraph>
            <Paragraph>
                Recht auf Berichtigung: Nach Art. 16 DSGVO hast Du das Recht,
                die unverzügliche Berichtigung oder Vervollständigung Deiner bei
                uns gespeicherten personenbezogenen Daten zu verlangen.
            </Paragraph>
            <Paragraph>
                Recht auf Löschung: Nach Art. 17 DSGVO hast Du das Recht, die
                Löschung Deiner bei uns gespeicherten personenbezogenen Daten zu
                verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts
                auf freie Meinungsäußerung und Information, zur Erfüllung einer
                rechtlichen Verpflichtung, aus Gründen des öffentlichen
                Interesses oder zur Geltendmachung, Ausübung oder Verteidigung
                von Rechtsansprüchen erforderlich ist.
            </Paragraph>
            <Paragraph>
                Widerspruch gegen die Datenerhebung in besonderen Fällen: Nach
                Art. 21 DSGVO hast Du das Recht, der Datenverarbeitung zur
                Wahrung berechtigter Interessen zu widersprechen, wenn sich aus
                Deiner besonderen Situation Gründe ergeben, die gegen die
                Datenverarbeitung sprechen. Wenn Widerspruch erhebst , werden
                wir Deine personenbezogenen Daten nicht mehr verarbeiten, es sei
                denn, wir können zwingende schutzwürdige Gründe für die
                Verarbeitung nachweisen, die Deine Interessen, Rechte und
                Freiheiten überwiegen, oder die Verarbeitung dient der
                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
            </Paragraph>
            <Paragraph fontWeight="bold">
                Möchtest Du von Deinem Widerspruchsrecht Gebrauch machen, genügt
                eine Nachricht an unseren Datenschutzbeauftragten, z.B. per
                E-Mail an{' '}
                <Link href="mailto:datenschutz-info@pohl-boskamp.de">
                    datenschutz-info@pohl-boskamp.de
                </Link>
                .
            </Paragraph>
            <Paragraph>
                Recht auf Einschränkung der Verarbeitung: Nach Art. 18 DSGVO
                hast Du das Recht, die Einschränkung der Verarbeitung Deiner
                personenbezogenen Daten zu verlangen.
            </Paragraph>
            <Paragraph>
                Recht auf Datenübertragbarkeit: Du hast das Recht, Daten, die
                wir auf Grundlage Deiner Einwilligung oder in Erfüllung eines
                Vertrags automatisiert verarbeiten, an sich oder an einen
                Dritten in einem gängigen, maschinenlesbaren Format aushändigen
                zu lassen. Sofern Du die direkte Übertragung der Daten an einen
                anderen Verantwortlichen verlangst, erfolgt dies nur, soweit es
                technisch machbar ist.
            </Paragraph>
            <Paragraph>
                Beschwerderecht bei der Aufsichtsbehörde: Nach Art. 77 DSGVO
                hast Du das Recht, sich bei einer Aufsichtsbehörde zu
                beschweren.
            </Paragraph>
            <Paragraph>
                Bei Fragen und zur Ausübung Deiner Rechte kannst Du Dich an
                unseren Datenschutzbeauftragten wenden. In bestimmten Fällen
                fordern wir möglicherweise zusätzliche Informationen bei Dir an,
                um Deine Identität festzustellen. So können wir z. B. bei der
                Wahrnehmung des Auskunftsanspruchs sicherstellen, dass
                Informationen nicht an unbefugte Personen herausgegeben werden.{' '}
                <b>
                    Datenschutzbeauftragten erreichst Du unter{' '}
                    <Link href="datenschutz-info@pohl-boskamp.de">
                        datenschutz-info@pohl-boskamp.de
                    </Link>
                </b>
                .
            </Paragraph>
            <Paragraph>
                G. Pohl-Boskamp GmbH & Co. KG
                <br />
                Datenschutzbeauftragter
                <br />
                Kieler Straße 11
                <br />
                25551 Hohenlockstedt.
            </Paragraph>
            <Paragraph>Stand der Teilnahmebedingungen: April 2022</Paragraph>
        </>
    ),
};
