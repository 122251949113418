import React from 'react';
import PropTypes from 'prop-types';

import {UnorderedList, OrderedList, ListItem} from './styles';
import {font} from '../../../styles/variables';

export const List = ({items, type, counterFontWeight, start, children}) => {
    const listItems = items.length
        ? items.map((item, key) => {
              return <ListItem key={key}>{item}</ListItem>;
          })
        : children;

    if (type === 'ol') {
        return (
            <OrderedList counterFontWeight={counterFontWeight} start={start}>
                {listItems}
            </OrderedList>
        );
    } else {
        return <UnorderedList>{listItems}</UnorderedList>;
    }
};

List.propTypes = {
    type: PropTypes.oneOf(['ul', 'ol']),
    items: PropTypes.arrayOf(PropTypes.any),
    counterFontWeight: PropTypes.oneOf(Object.keys(font.weight)),
    start: PropTypes.number,
};

List.defaultProps = {
    start: 0,
    items: [],
    counterFontWeight: 'regular',
};

export default List;
